import React, { useState, useEffect } from 'react'
import { TimelineMax, Power1 } from 'gsap';
import styles from './Header.module.scss';
import Video from '../assets/video/video.webm';

const Header = () => {

  const [tlHeader] = useState(new TimelineMax());
  let picture = null;
  let titleH1 = null;
  let titleH3 = null;

  useEffect(() => {
    tlHeader
      .from(titleH1, 0.6, {opacity: 0, y: '50px', ease: Power1.easeOut,}, .5)
      .from(titleH3, 0.6, {opacity: 0, y: '50px', ease: Power1.easeOut,})
      .from(picture, 0.5, {opacity: 0, scale: 0})
      // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <h1 className={styles.textH1} ref={h1 => titleH1 = h1}>Hosting Bydgoszcz na <span className={styles.titleSpan}>BluSerwer.pl</span></h1>
        <h3 className={styles.textH3} ref={h3 => titleH3 = h3}>Posiadamy duże doświadczenie w zakresie serwerów, bezpieczeństwa oraz technologi Web</h3>
      </div>
      <div className={styles.img} ref={divImg => picture = divImg}>
        <video autoPlay loop muted className={styles.videoHosting}>
          <source src={Video} type="video/webm" />
        </video>
      </div>
    </div>
  )
}

export default Header
