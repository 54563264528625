import React, { useEffect, useState } from 'react'
import { TimelineMax } from 'gsap';
import Slider from "react-slick";
import styles from './Testimonial.module.scss';

import Title from '../components/Title/Title';
import ItemTestimonial from '../components/ItemTestimonial/ItemTestimonial';


const useOnScreen = (options) => {
  const [ref, setRefTestimonial] = useState(null);
  const [visibleTestimonial, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRefTestimonial, visibleTestimonial]
}


const Testimonial = () => {

  const [setRefTestimonial, visibleTestimonial] = useOnScreen({threshold: 0.5})
  
  const [tlCompany] = useState(new TimelineMax());
  let h2Ref = null;

    useEffect(() => {
      if(visibleTestimonial) {
         tlCompany
          .to(h2Ref, 0.6, {y: 0, opacity: 1})
      }
      // eslint-disable-next-line
    }, [visibleTestimonial]);

  return (
    <div className={styles.wrapper} ref={setRefTestimonial}>
      <div className={styles.divTitle} >
        <Title titleRef={h2 => h2Ref = h2}>Poznaj <span className={styles.titleSpan}>co mówią</span> o nas nasi klienci</Title>
      </div>
      <div className={styles.mySlider}>
        <Slider
          speed={500}
          // autoplay={true}
          autoplaySpeed={3000}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
          dots={true}
          pauseOnHover={true}
          pauseOnFocus={true}
          className={styles.myClass}
        >
          <ItemTestimonial
            text={"Profesjonaliści w swoim fachu. Podczas wieloletniej współpracy wielokrotnie potwierdzali swoje umiejętności i poziom obsługi. Wiedzą, czego potrzebuje nowoczesna firma."}
            company={"Special Space"}
          />
          <ItemTestimonial 
            text={"E-Pulpit to świetne rozwiązanie dla naszej firmy. Szybko reagują na zapytania! Polecam każdemu!"}
            company={"AGES Adam Kolasiński"}
          />
          <ItemTestimonial 
            text={"Z czystym sumieniem mogę polecić usługę e-Pulpit24 każdej firmie chcącej zwiększyć elastyczność, operacyjną efektywność i ułatwić dostęp do danych w pracy."}
            company={"Complex Service"}
          />
        </Slider>
      </div>
    </div>
  )
}

export default Testimonial
