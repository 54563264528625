import React, { Fragment } from 'react';

import Header from '../Header/Header';
import Company from '../Company/Company';
import Panel from '../components/Panel/Panel';
import Customers from '../Customers/Customers';
import Products from '../Products/Products';
import HeroSection from '../HeroSection/HeroSection';
import Footer from '../Footer/Footer';
import Testimonial from '../Testimonial/Testimonial';

function Home() {
  return (
    <Fragment>
      <Panel />
      <Header />
      <Company />
      <Customers />
      <Products />
      <Testimonial />
      <HeroSection />
      <Footer />
    </Fragment>
  )
}

export default Home;