import React, { useEffect, useState } from 'react'
import { TimelineMax } from 'gsap';
import styles from './Products.module.scss';

import Title from '../components/Title/Title';
import ItemProduct from '../components/ItemProduct/ItemProduct';


const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])

  return [setRef, visible]
}


const Products = () => {

  const [setRef2, visible] = useOnScreen({threshold: 0.8})
  
  const [tlTitle] = useState(new TimelineMax());
  let h2Ref = null;
  // let boxHosting = null;


    useEffect(() => {
      if(visible) {
         tlTitle
          .to(h2Ref, 0.6, {y: 0, opacity: 1})
          // .to(boxHosting, 0.5, {y: 0, opacity: 1})
      }
      // eslint-disable-next-line
    }, [visible]);

  return (
    <div className={styles.wrapper} >
      <div className={styles.divTitle} ref={setRef2}>
        <Title titleRef={h2 => h2Ref = h2}>Zachęcamy do zapoznania się z <span className={styles.titleSpan}>naszą</span> ofertą</Title>
      </div>
      <ItemProduct/>
      {/* <ItemProduct boxRef={div => boxHosting = div} /> */}
    </div>
  )
}

export default Products
