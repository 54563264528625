import React from 'react';
import styles from './ErrorPage.module.scss';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>404</h1>
      <h3 className={styles.text}>Podana strona nie istnieje</h3>
      <Link exact
        className={styles.backLink} 
        to="/">Strona główna</Link>
    </div>
  )
}

export default ErrorPage
