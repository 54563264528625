import React, { useEffect, useState } from 'react'
import { TimelineMax } from 'gsap';
import styles from './HeroSection.module.scss';
import Title from '../components/Title/Title';

import heroImg from '../assets/images/network.jpg'

const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])

  return [setRef, visible]
}


function HeroSection() {

  const [setRef, visible] = useOnScreen({threshold: 0.5})
  
  const [tl] = useState(new TimelineMax());
  let h2Ref = null;
  let pRef = null;

    useEffect(() => {
      if(visible) {
         tl
          .to(h2Ref, 0.6, {y: 0, opacity: 1})
          .to(pRef, 0.7, {y: 0, opacity: 1})
      }
      // eslint-disable-next-line
    }, [visible]);

  return (
    <div className={styles.wrapper} ref={setRef}>
      <div className={styles.leftSide}>
        <Title titleRef={h2 => h2Ref = h2}>Szybkie, stabilne pakiety hostingowe dopasowane <span className={styles.titleSpan}>do Twoich</span> potrzeb</Title>
        <p className={styles.text} ref={h3 => pRef = h3}>Naszym priorytetem jest budowanie oraz wzmacnianie relacji z naszymi klientami. Jest to możliwe dzięki naszemu indywidualnemu podejściu do każdego klienta oraz zwracania szczególnej uwagi na jego potrzeby. Nasi doświadczeni oraz wykwalifikowani eksperci pomogą Ci stworzyć unikalną architekturę dla Twojej aplikacji. Tak wiele za tak niską cenę tylko od BluSerwer.</p>
        <a className={styles.link} href="https://bluserwer.pl" target="_blank" rel="noopener noreferrer">Zapraszamy</a>
      </div>
      <div className={styles.rightSide}>
        <img className={styles.img} src={heroImg} alt="#"/>
      </div>
    </div>
  )
}

export default HeroSection
