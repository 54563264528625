import React from 'react';
import styles from './WpAdmin.module.scss';

import gif from '../assets/images/giphy.gif'

const WpAdmin = () => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.gifAnimation} src={gif} alt="#"/>
    </div>
  )
}

export default WpAdmin;