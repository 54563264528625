import React, { useEffect, useState } from 'react';
import { TimelineMax } from 'gsap';
import styles from './Company.module.scss';

import Title from '../components/Title/Title';


const useOnScreen = (options) => {
  const [ref, setRefAbout] = useState(null);
  const [visibleAbout, setVisible] = useState(false);

  useEffect(() => {

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])

  return [setRefAbout, visibleAbout]
}


const Company = () => {

  const [setRefAbout, visibleAbout] = useOnScreen({threshold: 0.5})
  
  const [tlCompany] = useState(new TimelineMax());
  let h2Ref = null;
  let textAbout = null;

    useEffect(() => {
      if(visibleAbout) {
         tlCompany
          .to(h2Ref, 0.6, {y: 0, opacity: 1})
          .to(textAbout, 0.7, {y: 0, opacity: 1})
      }
      // eslint-disable-next-line
    }, [visibleAbout]);

  return (
    <div className={styles.wrapper} ref={setRefAbout}>

      <Title titleRef={h2 => h2Ref = h2}>Najważniejsze dla Nas to <span className={styles.titleSpan}>pozytywne relacje</span> z Naszymi klientami</Title>
      <p ref={p => textAbout = p} className={styles.textAbout}>
      BluSerwer.pl to najszybszy serwer w regionie, dzięki ciągłemu inwestowaniu w nowe technologie. Nasze pomieszczenia serwerowe zapewniają bezpieczeństwo Twoim danym. Jako firma specjalizujemy się w zarządzaniu serwerami, hostingiem serwerów dedykowanych, obsługą informatyczną firm, CRM, systemami kadrowo – księgowymi, systemami wspomagającymi zarządzanie firmą i bezpieczeństwem. Mamy świadomość jak istotne jest zapewnienie bezpieczeństwa i niezawodności pracy systemów. Nasze doświadczenie poparte jest certyfikatami oraz szkoleniami z zakresu: rozwoju osobistego, informatyki, Cloud, Microsoft i środowiska Windows.
      </p>
 
    </div>
  )
}

export default Company
