import React from 'react'
import styles from './ItemTestimonial.module.scss';

const ItemTestimonial = ({text, company}) => {
  return (
    <div className={styles.oneTestimonial}>
      <p className={styles.oneTestimonialText}>
        <i style={{color: '#00D4F5', marginRight: '8px'}} className="fas fa-quote-right"/> 
          {text}
        <i style={{color: '#00D4F5', marginLeft: '8px'}} className="fas fa-quote-left"/>
      </p>

      <p className={styles.oneTestimonialCompany}>
        {company}
      </p>
    </div>
  )
}

export default ItemTestimonial
