import React, { useState } from 'react'
import styles from './Panel.module.scss';
import Logo from '../../assets/images/BluSerwer-logo-blue.png';

function Panel() {

  const [Smile, setSmile] = useState(false);
  const onMouseEnter = () => setSmile(!Smile);
  const onMouseLeave = () => setSmile(!Smile);

  return (
    <div className={styles.wrapper}>
      <a href="https://bluserwer.pl" target="_blank" rel="noopener noreferrer">
        <img className={styles.logo} src={Logo} alt="BluSerwer"/>
      </a>
      <a className={styles.contact} href="mailto:zgloszenia@bluserwer.pl" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <p className={styles.text}>Napisz 
          <span className={styles.titleSpan}> do nas {Smile ? <i className="far fa-laugh-beam"/> : <i className="far fa-laugh-wink"/>} </span>
        </p>
      </a> 
    </div>
  )
}

export default Panel
