import React, { useEffect, useState } from 'react'
import { TimelineMax } from 'gsap';
import styles from './Customers.module.scss';

import dell from '../assets/images/loga/dell.png';
import expert from '../assets/images/loga/expert.png';
import hewlettPackard from '../assets/images/loga/hewlett-packard.png';
import intercor from '../assets/images/loga/intercor.png';
import microsoft from '../assets/images/loga/microsoft.png';
import refinanse from '../assets/images/loga/refinanse.png';
import sichert from '../assets/images/loga/sichert.png';
import wsg from '../assets/images/loga/wsg.png';


const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {

    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])

  return [setRef, visible]
}


const Customers = () => {

  const [setRef, visible] = useOnScreen({threshold: 0.5})

  const [tl] = useState(new TimelineMax());
  let h4Ref = null;
  let img01 = null;
  let img02 = null;
  let img03 = null;
  let img04 = null;
  let img05 = null;
  let img06 = null;
  let img07 = null;
  let img08 = null;

    useEffect(() => {
      if(visible) {
         tl
          .to(h4Ref, 0.6, {y: 0, opacity: 1})
          .to(img01, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img02, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img03, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img04, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img05, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img06, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img07, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
          .to(img08, 0.4, {y: 0, opacity: 1, scale: 1}, -'0.1')
      }
      // eslint-disable-next-line
    }, [visible]);

  return (
    <div className={styles.wrapper} ref={setRef}>
      <h4 ref={h4 => h4Ref = h4} className={styles.title}>
        Pracujemy jako <span className={styles.titleSpan}>jeden zespół</span> z naszymi partnerami, aby dostarczać najlepsze produkty dla was.
      </h4>
      <div className={styles.logos}>
        <img ref={img => img01 = img} className={styles.logosImg} src={dell} alt="dell"/>
        <img ref={img => img02 = img} className={styles.logosImg} src={expert} alt="expert"/>
        <img ref={img => img03 = img} className={styles.logosImg} src={hewlettPackard} alt="hewlett-packard"/>
        <img ref={img => img04 = img} className={styles.logosImg} src={intercor} alt="intercor"/>
        <img ref={img => img05 = img} className={styles.logosImg} src={microsoft} alt="microsoft"/>
        <img ref={img => img06 = img} className={styles.logosImg} src={refinanse} alt="refinanse"/>
        <img ref={img => img07 = img} className={styles.logosImg} src={sichert} alt="sichert"/>
        <img ref={img => img08 = img} className={styles.logosImg} src={wsg} alt="wsg"/>
      </div>
    </div>
  )
}

export default Customers
