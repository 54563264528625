import React, { Fragment } from 'react'
import styles from './Title.module.scss';

const Title = ({children, titleRef}) => {
  return (
    <Fragment>
      <h2 className={styles.title} ref={titleRef}>{children}</h2>
    </Fragment>
  )
}

export default Title
